import { ResponseError } from "superagent";
import useSWRImmutable from "swr/immutable";
import { useUserService } from "../services";
import { GuestAction } from "../types";

export interface guestActionValidationData {
  logoURL: string | undefined;
  secondsSinceLastEmailSent: number | undefined;
}

export function useValidateGuestAction(
  action: GuestAction,
  orgUUID: string,
  meetingUUID: string,
  contactUUID: string,
) {
  const service = useUserService();
  const body = {
    a: action,
    c: contactUUID,
    m: meetingUUID,
    o: orgUUID,
  };

  const { data, error } = useSWRImmutable(
    [`/api/meeting/guest/action/validate`, body],
    (u: string) =>
      service
        .post(u)
        .send(body)
        .then((res: Response) => res.body),
  );

  return {
    data: (data as guestActionValidationData) || undefined,
    error: error as ResponseError,
    loading: !error && !data,
  };
}
